import { useEffect, useState } from "react"

const getItem = (key: any) => {
  if (typeof document !== undefined) {
    return document.cookie.split(`; `).reduce((total, currentCookie) => {
      const item = currentCookie.split(`=`)
      const storedKey = item[0]
      const storedValue = item[1]

      return key === storedKey ? decodeURIComponent(storedValue) : total
    }, ``)
  }
  return ""
}

const setItem = (key: any, value: any, numberOfDays: any) => {
  const now = new Date()

  // set the time to be now + numberOfDays
  now.setTime(now.getTime() + numberOfDays * 60 * 60 * 24 * 1000)
  if (typeof document !== undefined) {
    document.cookie = `${key}=${value}; expires=${now.toUTCString()}; path=/`
  }
}

export const useCookie = (key: string) => {
  const getCookie = () => getItem(key) || "undefined"
  const [cookie, setCookie] = useState<any>()

  useEffect(() => {
    setCookie(getCookie())
  }, [])

  const updateCookie = (value: any, numberOfDays: any) => {
    setCookie(value)
    setItem(key, value, numberOfDays)
  }

  return { cookie, updateCookie }
}
