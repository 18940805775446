module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icons/Sebenza_Favicon.png","name":"Sebenza","short_name":"Sebenza","start_url":"/home/?check=data","background_color":"#f7f0eb","theme_color":"#a2466c","display":"standalone","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"baea9a337f999a8b9bd88e4d656052c6"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-4BZ05JLC49"],"gtagConfig":{}},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[],"precachePages":["/home/","/"],"appendScript":"/home/runner/work/webV2/webV2/src/custom-sw-code.js"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[],"dsn":"https://80cda66a7bb74924babe2ac306c1f366@o1177640.ingest.sentry.io/4504213015363584","sampleRate":0.7},
    },{
      plugin: require('../node_modules/gatsby-plugin-tealium/gatsby-browser.ts'),
      options: {"plugins":[],"scriptSrc":"https://js.adsrvr.org/up_loader.1.1.0.js","domReadyEvent":"\n          if (typeof TTDUniversalPixelApi === 'function') {\n            var universalPixelApi = new TTDUniversalPixelApi();\n            universalPixelApi.init(\"e3fs1i1\", [\"4vz4idi\"], \"https://insight.adsrvr.org/track/up\");\n          }\n        "},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
