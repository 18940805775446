import React, { createContext, useState, useMemo, useEffect } from "react"
import { useCookie } from "../../hooks/coockie"

export const LoadingStateContext: any = createContext({
  isLoading: false,
  setIsLoading: () => {},
})

export const LoadingContext = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false)

  const value = useMemo(() => ({ isLoading, setIsLoading }), [isLoading])

  return <LoadingStateContext.Provider value={value}> {children} </LoadingStateContext.Provider>
}
