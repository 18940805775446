import React from "react"
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client"
import { UserContext } from "./context/userContext"
import { MusicContext } from "./context/musicContext"
import { SsidContext } from "./context/ssidContext"
import { KeyboardContext } from "./context/keyboardContext"
import Pwa from "./context/pwa"
import { LoadingContext } from "./context/loadingContext"
import { Script } from "gatsby"

const client = new ApolloClient({
  uri: `${process.env.GATSBY_API_URL}/graphql`,
  cache: new InMemoryCache(),
  credentials: "include",
})

export const wrapRootElement = ({ element }) => {
  return (
    <ApolloProvider client={client}>
      <UserContext>
        <Pwa>
          <SsidContext>
            <KeyboardContext>
              <LoadingContext>
                <>{element}</>
              </LoadingContext>
            </KeyboardContext>
          </SsidContext>
        </Pwa>
      </UserContext>
    </ApolloProvider>
  )
}
