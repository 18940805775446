import { gql, useMutation } from "@apollo/client"
import React, { createContext, useContext, useEffect, useMemo, useState } from "react"
import { UserStateContext } from "./userContext"

const UPDATE_USER = gql`
  mutation Mutation($input: UpsertUserInput!) {
    updateUser(input: $input) {
      hasInstalledApp
    }
  }
`

export const PwaContext: any = createContext({
  prompt: null,
  setPrompt: () => {},
})

const Pwa = ({ children }) => {
  const { setUserSb } = useContext(UserStateContext)
  const [updateUser, { data }] = useMutation(UPDATE_USER)
  const [prompt, setPrompt] = useState<any>()

  const recordAppInstalled = (evt: any) => {
    fetch(`${process.env.GATSBY_API_URL}/pwa/installed`, {
      method: "POST",
      headers: { "Content-Type": "application/json", Accept: "application/json" },
      credentials: "include",
    })
      .then(res => res.json())
      .then(res => {
        if (res.user.sebenzaBucks) {
          setUserSb(res.user.sebenzaBucks)
        }
        setPrompt(null)
      })
      .catch(e => {})
  }

  useEffect(() => {
    window.addEventListener("beforeinstallprompt", event => {
      event.preventDefault()
      setPrompt(event)
    })
  }, [])

  useEffect(() => {
    window.addEventListener("appinstalled", recordAppInstalled)
    return () => {
      window.removeEventListener("appinstalled", recordAppInstalled)
    }
  }, [])

  const value = useMemo(() => ({ prompt, setPrompt }), [prompt])

  return <PwaContext.Provider value={value}>{children}</PwaContext.Provider>
}

export default Pwa
