import { navigate } from "gatsby"
import config from "../config.json"

export const fetchAnalytics = (url: string, body: string) => {
  fetch(`${process.env.GATSBY_API_URL}${url}`, {
    method: "POST",
    headers: { "Content-Type": "application/json", Accept: "application/json" },
    credentials: "include",
    body,
  })
    .then(res => res.json())
    .then(res => {
      if (res?.status === "no user found" && !config.whiteList.includes(window.location.pathname)) navigate("/")
    })
    .catch(e => {})
}
