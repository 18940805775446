import React, { createContext, useState, useMemo } from "react"

export const KeyboardStateContext: any = createContext({
  userSb: 0,
  setUserSb: () => {},
})

export const KeyboardContext = ({ children }) => {
  const [isKeyboardShowing, setIsKeyboardShowing] = useState(false)

  const value = useMemo(() => ({ isKeyboardShowing, setIsKeyboardShowing }), [isKeyboardShowing])

  return <KeyboardStateContext.Provider value={value}>{children}</KeyboardStateContext.Provider>
}
