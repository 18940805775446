import React, { createContext, useState, useMemo, useEffect } from "react"
import { useCookie } from "../../hooks/coockie"

export const SsidStateContext: any = createContext({
  ssid: "",
  setSsid: () => {},
})

export const SsidContext = ({ children }) => {
  const [ssid, setSsid] = useState<any>("")
  const { cookie, updateCookie } = useCookie("ssid")

  useEffect(() => {
    if (cookie && cookie !== "undefined" && ssid === "") {
      setSsid(cookie)
    }
  }, [cookie])

  useEffect(() => {
    if (ssid && ssid !== "") {
      updateCookie(ssid, 1)
    }
  }, [ssid])

  const clearState = () => {
    setSsid("undefined")
  }

  const value = useMemo(() => ({ ssid, setSsid, clearState }), [ssid])

  return <SsidStateContext.Provider value={value}>{children}</SsidStateContext.Provider>
}
