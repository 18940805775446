import React, { createContext, useState, useMemo } from "react"

export const UserStateContext: any = createContext({
  userSb: 0,
  setUserSb: () => {},
})

export const UserContext = ({ children }) => {
  const [userSb, setUserSb] = useState()

  const value = useMemo(() => ({ userSb, setUserSb }), [userSb])

  return <UserStateContext.Provider value={value}>{children}</UserStateContext.Provider>
}
